.home-holder{
    height: 100vh;
}

.user-form{
    width: 100%;
    max-width: 600px;
}
.spinner{
    width: 30px;
    height: 30px;
}

button{
    width: 200px;
}